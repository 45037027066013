import React from "react"

import HELM from "../../components/helmet.js"
import Layout from "../../components/layout/layout.js"
import NewArrivalsCatalog from "../../components/newArrivalsCatalog/newArrivalsCatalog"

export default function NewMaternity() {
  return (
    <Layout>
      <HELM
        title="New Arrivals"
        keywords={[`New Clothes`, `New Arrivals`, `Fashion`, `Trend`]}
      />
      <NewArrivalsCatalog collection="maternity" />
    </Layout>
  )
}
